import React from 'react';

class RegionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      region: this.props.data_region,
      isOpen: false,
    };
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setDropdownRef = (node) => {
    this.dropdownRef = node;
  };

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  handleChange = (value) => {
    this.setState({ region: value, isOpen: false });
    this.setCookie(value);
  };

  setCookie = (value) => {
    const date = new Date(new Date().getTime() + 1209600000).toUTCString(); // 2 weeks from now
    document.cookie = `data_region=${encodeURIComponent(
      value,
    )}; expires=${date}; path=/; Secure;`;
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    return (
      <div className="custom-select" ref={this.setDropdownRef}>
        <div
          className="custom-select-trigger bg-primary dropdown-toggle pr-2"
          onClick={this.toggleDropdown}
        >
          {this.props.options[this.state.region]}
        </div>
        {this.state.isOpen && (
          <div className="custom-options">
            {Object.entries(this.props.options).map(([value, label]) => (
              <div
                key={value}
                className="custom-option dropdown-item"
                onClick={() => this.handleChange(value)}
              >
                {label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default RegionSelector;
