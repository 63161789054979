import axios from 'axios';
import {useEffect, useState} from 'react';
import GlFlightpathProcedure from './GlFlightpathProcedure';
import FlightpathProgressBar from "../../Shared/FlightPathProgressBar";
import {mapKeys, snakeCase} from "lodash";
import csrfToken from "../../../src/utils/csrf";
import Callbacks from "../../../src/utils/Callbacks.js"
import ResolveModal from "./Shared/ResolveModal";

const GlFlightpathApp = (props) => {

	const procedures = props.procedures;
	const fulfillment = props.fulfillment
	const [currentProcIndex, setCurrentProcIndex] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [completedProcedureMap, setCompletedProcedureMap] = useState({});
	const callbacks = new Callbacks();

	const advanceProcedures = (increaseValue = 1) => {
		setCurrentProcIndex(currentProcIndex + increaseValue);
	}

	const specificallySetProcedureIndex = (e) => {
		setCurrentProcIndex(parseInt(e.target.dataset.procIndex));
	};

	const markProcCompleted = (proc_id) => {
		const procBreadcrumb = document.querySelector(`[data-procedure="${proc_id}"]`);
		if(!procBreadcrumb.classList.contains('completed')) {
			procBreadcrumb.classList.add('completed');
		}
	};

	const openModal = () => {
		setShowModal(true);
	}

	const closeModal = () => {
		setShowModal(false);
	}

	// Gets the most recent event for the fulfillment
	// usage getFulfillmentEvents(['Event::FulfillmentScheduled','Event::Fake' ])
	const getFulfillmentEvents = (eventTypes,  responseFunction= undefined) => {
		axios.get(
			`${window.location.origin}/api/fulfillments/${fulfillment.uuid}/events?types=${eventTypes.join(',')}`,
			{},
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken()
			}
		)
			.then( response => {
				console.log(response);
				if ( responseFunction !== undefined ) {
					responseFunction(response);
				}
			})
			.catch( error => {
				console.log(error);
			});
	};

	const getVideoPlayerEvent = (eventType,  responseFunction= undefined) => {
		axios.get(
			`${window.location.origin}/api/fulfillments/${fulfillment.uuid}/events/video_player?types=${eventType}`,
			{},
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken()
			}
		)
			.then( response => {
				console.log(response);
				if ( responseFunction !== undefined ) {
					responseFunction(response);
				}
			})
			.catch( error => {
				console.log(error);
			});
	};


	const getCompletedEvents = () => {
		axios.get(
			`${window.location.origin}/api/fulfillments/${fulfillment.uuid}/events/completed`,
			{},
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken()
			}
		)
			.then( response => {
				console.log(response);
				setCompletedProcedureMap(response.data)
			})
			.catch( error => {
				console.log(error);
			});
	};

	const sendEvent = (eventType, payload, eventEndpointType = 'gl_flightpath', responseFunction= undefined, errorFunction= undefined) => {
		const eventPayload = {
			uuid: fulfillment.uuid,
			type: eventType,
			...payload
		};

		// this would be an axios call or possible action cable sequence to send the events
		console.log(`Sending ${eventType} event:`, eventPayload);
		axios.post(
			`${window.location.origin}/api/secure_browser/events/${eventEndpointType}`,
			mapKeys(eventPayload, (value, key) => snakeCase(key)),
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken()
			}
		)
			.then( response => {
				console.log(response);
				if ( responseFunction !== undefined ) {
					responseFunction(response);
				}
			})
			.catch( error => {
				console.log(error);
				if ( errorFunction !== undefined ) {
					errorFunction(error);
				}
			});
	};

	useEffect(() => {
		getCompletedEvents()
	},[])

	const markAllCompletedProcedures = () => {
		const checkedTypes = Object.entries(completedProcedureMap).filter(([key, value]) => value === true).map(([key, value]) => key)

		procedures.map((procedure) => {
			if(checkedTypes.includes(procedure.proc_type)) {
				markProcCompleted(procedure.id)
			}
		})
	}

	markAllCompletedProcedures()

	return (
		<div className={'card-body pt-0 px-0'}>
			<div className={'row mb-4'}>
				<div className={'col-md-12'}>
					<FlightpathProgressBar totalProcs={procedures.length} currentProcIndex={currentProcIndex}/>
				</div>
			</div>
			<div className={'row px-4'}>
				<div className={'col-1 p-0 flex-grow-0 flex-shrink-1'}>
					<ul className={'nav nav-pills text-center nav-flightpath flex-column'}>
						{ procedures.map( (proc, index) => {
							let active_class = index == currentProcIndex ? 'active' : '';
							return(
								<li className={`nav-item mb-1`} key={`proc_tab_${index}`} data-procedure={proc.id}>
									<a href={'#'} data-toggle={'tab'} className={`nav-link border p-1 py-2 ${active_class}`} onClick={specificallySetProcedureIndex} data-proc-index={index}>{index + 1}</a>
								</li>
							)
							})
						}
					</ul>
				</div>
				<div className={'col-11'}>
					<div className={'card h-100'}>
						<div className={'card-body tab-content'}>
							<GlFlightpathProcedure
								key={`proc_${currentProcIndex}`}
								procedure={procedures[currentProcIndex]}
								proc_index={currentProcIndex}
								sendEvent={sendEvent}
								handleNext={advanceProcedures}
								markProcCompleted={markProcCompleted}
								openModal={openModal}
								getFulfillmentEvents={getFulfillmentEvents}
								fulfillment={fulfillment}
								completedProcedureMap={completedProcedureMap}
								callbacks={callbacks}
								getVideoPlayerEvent={getVideoPlayerEvent}
							/>
						</div>
					</div>
				</div>
			</div>
			<ResolveModal
				onShow={()=> {console.log('Showed it')}}
				showModal={showModal}
				openModal={openModal}
				closeModal={closeModal}
				sendEvent={sendEvent}
				procedure={procedures[currentProcIndex]}
			/>
		</div>
	)

}

export default GlFlightpathApp;
