/*jshint esversion: 6 */

import { useEffect, useState } from 'react';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';

const SecondCameraLaunchCard = ({
  id,
  icon,
  iconPrefix,
  title,
  sendEvent,
  precheckData,
  handleSecureBrowserMessage,
}) => {
  const [showQrCode, setShowQrCode] = useState(true);

  const image = precheckData.images.setupPhoneImg;

  const toggleQrCode = (e) => {
    e.preventDefault();
    setShowQrCode(!showQrCode);
  };

  const launchUrl = window.origin + '/' + precheckData.secondCameraLaunchUrl;

  useEffect(() => {
    sendEvent('Event::SecondCameraLaunchLoaded');

    // Temporary, for testing: Sends a fake second_camera_added event when you press Ctrl-Q,
    // to allow moving forward to the next step, until we have this hooked up in Guardian Browser.
    function handleKeyDown(event) {
      if (event.ctrlKey && event.key === 'q') {
        handleSecureBrowserMessage({ messageType: 'second_camera_added' });
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <div className="row">
        <span className="ml-auto">
          <i className="fal fa-wifi fa-1x mr-2 precheck-blinking-icon" />
          {polyglot.t('prechecks_second_camera_connecting')}
        </span>
      </div>
      <PrechecksHeaderGroup
        title={title}
        image={image}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <div className="row mt-5 mb-5">
        <div className="text-center col-md-12">
          <img
            hidden={!showQrCode}
            src={`/internal/qr_code?url=${encodeURIComponent(launchUrl)}`}
            className="img-fluid mx-auto col-md-2 offset-md-5"
          />
          <div hidden={showQrCode}>
            {polyglot.t('prechecks_second_camera_link_instructions')}
            <br />
            {launchUrl}
          </div>
        </div>
      </div>
      <div className="row text-left">
        <PrechecksBodyText
          bodyText={polyglot.t('prechecks_second_camera_launch_instructions')}
        />
        <div>
          <a
            href="#"
            className="precheck-a"
            hidden={!showQrCode}
            onClick={toggleQrCode}
          >
            {polyglot.t('prechecks_second_camera_launch_qr_trouble')}
          </a>
          <a
            href="#"
            className="precheck-a"
            hidden={showQrCode}
            onClick={toggleQrCode}
          >
            {polyglot.t('prechecks_second_camera_launch_link_trouble')}
          </a>
        </div>
      </div>
    </div>
  );
};

SecondCameraLaunchCard.defaultProps = {
  title: polyglot.t('prechecks_second_camera_launch_title'),
  statusColor: 'primary',
  icon: 'fa-mobile',
  iconPrefix: 'fal',
};

export default SecondCameraLaunchCard;
