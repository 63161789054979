import React, { useState, useEffect } from 'react';

const timeAgo = (date) => {
  const now = new Date();
  const past = new Date(date);
  const diff = now - past; // difference in milliseconds

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else {
    return `${days} days ago`;
  }
};

// TODO: we need to get this up to the types file so that we can use it in multiple places
/**
 * @typedef {Object} ChatRoom
 * @property {number} id
 * @property {number} test_taker_id
 * @property {string} test_taker_name
 * @property {string} fulfillment_uuid
 * @property {number[]} user_ids
 * @property {boolean} proctor_connected
 * @property {string} [proctor_connected_at]
 * @property {number} room_size
 * @property {boolean} active
 * @property {string} created_at
 * @property {string} updated_at
 */

/**
 * @typedef {Object} ManagerTableProps
 * @property {ChatRoom[]} chatRooms
 * @property {number} userId
 * @property {(id: number) => void} onConnect
 * @property {(id: number) => void} onDelete
 */

/**
 * A table that displays chat rooms and allows internal users to filter/manage them in real time.
 *
 * @param {ManagerTableProps} props
 * @returns {React.ReactElement}
 */
export default function ManagerTable({
  chatRooms,
  userId,
  onConnect,
  onDelete,
}) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60_000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section>
      <h1 className="py-2 h3">Chat Room Manager</h1>

      <div className="d-flex justify-content-between align-items-center">
        {/* TODO: need some filters here */}
      </div>

      <table className="table table-striped text-center mt-2">
        <thead>
          <tr className="bg-primary text-white">
            <th className="align-middle">ID</th>
            <th className="align-middle">Size</th>
            <th className="align-middle">Test Taker</th>
            <th className="align-middle">Fulfillment</th>
            <th className="align-middle">Proctor Connected</th>
            <th className="align-middle">Proctor Connected At</th>
            <th className="align-middle">Created At</th>
            <th className="align-middle">Actions</th>
          </tr>
        </thead>
        <tbody>
          {chatRooms?.length < 1 && (
            <td className="text-center" colSpan="8">
              No chat rooms found 🧹
            </td>
          )}
          {(chatRooms ?? [])
            .filter((cr) => cr.active)
            .map((chatRoom) => (
              <tr key={chatRoom.id}>
                <td className="align-middle">
                  <strong>{chatRoom.id}</strong>
                </td>
                <td className="align-middle">
                  <span className="text-muted mr-2">
                    <i className="fa fa-users" />
                  </span>
                  {chatRoom.room_size}
                </td>
                <td className="align-middle">
                  {chatRoom.test_taker_name && (
                    <a
                      href={`/users/${chatRoom.test_taker_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chatRoom.test_taker_name}
                      <i className="fa fa-external-link ml-2" />
                    </a>
                  )}
                </td>
                <td className="align-middle">
                  {chatRoom.fulfillment_uuid && (
                    <a
                      href={`/fulfillments/${chatRoom.fulfillment_uuid}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-external-link ml-2" />
                    </a>
                  )}
                </td>
                <td className="align-middle">
                  {chatRoom.proctor_connected && (
                    <span className="text-success">
                      🟢 Connected{' '}
                      {chatRoom.user_ids.includes(userId) && '(you)'}
                    </span>
                  )}
                </td>
                <td className="align-middle">
                  {chatRoom.proctor_connected_at && (
                    <span className="text-muted">
                      {timeAgo(chatRoom.proctor_connected_at)}
                    </span>
                  )}
                </td>
                <td className="align-middle">
                  <span className="text-muted">
                    {timeAgo(chatRoom.created_at)}
                  </span>
                </td>
                <td className="align-middle">
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-success mr-2"
                      onClick={() => onConnect(chatRoom.id)}
                    >
                      <i className="fa fa-arrow-up-right-from-square" />
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => onDelete(chatRoom.id)}
                    >
                      <i className="fa fa-check" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  );
}
