const ActionButtons = (props) => {
	const resolveBtnText = props.resovleBtnText;
	const confirmBtnText = props.confirmBtnText;
	const confirmBtnAction = props.confirmBtnAction;
	const resolveBtnAction = props.resolveBtnAction;

	return (
		<div className={'row mb-4 text-center'}>
			<div className={'col-md-12'}>
				{ (resolveBtnText.length > 0) &&
					<button className={'btn btn-danger mr-2'} onClick={resolveBtnAction}>{resolveBtnText}</button>
				}
				{ (confirmBtnText.length > 0) &&
					<button className={'btn btn-success'} onClick={confirmBtnAction}>{confirmBtnText}</button>
				}
			</div>
		</div>
	)
}

export default ActionButtons;