const ExamInfoContainer = ({ precheckStep, precheckData }) => {
  const title = precheckData?.exam?.name ?? '';

  const institutionName = precheckData?.institution?.name ?? '';
  const instructorName = precheckData?.exam?.instructorName ?? '';
  const subtitle = instructorName
    ? polyglot.t('prechecks_exam_info_subtitle_institution_and_instructor', {
        institution_name: institutionName,
        instructor_name: instructorName,
      })
    : polyglot.t('prechecks_exam_info_subtitle_institution', {
        institution_name: institutionName,
      });

  return (
    <div
      style={{
        position: 'absolute',
        maxWidth: '18rem',
        color: '#6c757d',
        fontWeight: '600',
      }}
    >
      {title && <p style={{ fontSize: '14px', marginBottom: 0 }}>{title}</p>}
      {institutionName && <p style={{ fontSize: '12px' }}>{subtitle}</p>}
    </div>
  );
};

export default ExamInfoContainer;
