/** @typedef {import('choices.js').Options} Choices.Options */
/** @typedef {typeof import('choices.js').templates} Choices.Templates */

import Choices from 'choices.js';

import csrfToken from '../../src/utils/csrf';

class ExamSelect {
  /** @type {string[]} */
  #customEligibilityInstitutionsIds = [];

  constructor() {
    //#region regular form

    this.regularForm =
      /** @type {HTMLDivElement} */
      document.getElementById('regular-form');
    this.institutionSelect =
      /** @type {HTMLSelectElement} */
      document.querySelector('[data-behavior="institution-select"]');
    this.examTypeSelect =
      /** @type {HTMLSelectElement} */
      document.querySelector('[data-behavior="exam-type-select"]');
    this.termSelect =
      /** @type {HTMLSelectElement} */
      document.querySelector('[data-behavior="term-select"]');
    this.examSelect =
      /** @type {HTMLSelectElement} */
      document.querySelector('[data-behavior="exam-select"]');
    this.submitButton =
      /** @type {HTMLButtonElement} */
      document.querySelector('[data-behavior="submit-exam-search"]');

    this.selectedTerm = /** @type {HTMLOptionElement} */ { value: '' };

    // options change handlers
    this.handleInstitutionChange = this.handleInstitutionChange.bind(this);
    this.handleExamTypeChange = this.handleExamTypeChange.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.handleExamChange = this.handleExamChange.bind(this);

    this.handleCSU = this.handleCSU.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    // terms section
    this.fetchTerms = this.fetchTerms.bind(this);
    this.setTerms = this.setTerms.bind(this);
    this.toggleTerms = this.toggleTerms.bind(this);
    this.resetTermOptions = this.resetTermOptions.bind(this);

    // exams section
    this.fetchExams = this.fetchExams.bind(this);
    this.setExams = this.setExams.bind(this);
    this.toggleExams = this.toggleExams.bind(this);
    this.populateExamDropdown = this.populateExamDropdown.bind(this);

    // "Find Sessions" button
    this.toggleSubmitButton = this.toggleSubmitButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.termChoice = this.#createChoices(this.termSelect, 'term-select');
    this.noTerms = [{ id: 'noTerms', name: polyglot.t('no_active_terms') }];
    this.termPlaceholder = [
      { id: 'termPlaceholder', name: polyglot.t('please_select') },
    ];
    this.examChoice = this.#createChoices(this.examSelect, 'exam-select');
    this.examChoice.disable();
    this.noExams = [{ id: 'noExams', name: polyglot.t('no_active_exams') }];
    this.examPlaceholder = [
      { id: 'examPlaceholder', name: polyglot.t('please_select') },
    ];

    //#endregion regular form

    //#region custom eligibility form

    this.customEligibilityForm =
      /** @type {HTMLDivElement} */
      document.getElementById('custom-eligibility-form');
    this.voucherInput =
      /** @type {HTMLInputElement} */
      document.getElementById('voucher-input');
    this.applyVoucherButton =
      /** @type {HTMLButtonElement} */
      document.getElementById('apply-voucher-button');
    this.loadingButton =
      /** @type {HTMLButtonElement} */
      document.getElementById('loading-button');
    this.resultMessage =
      /** @type {HTMLSpanElement} */
      document.getElementById('result-message');
    this.departmentInput =
      /** @type {HTMLInputElement} */
      document.getElementById('department-input');
    this.examInput =
      /** @type {HTMLInputElement} */
      document.getElementById('exam-input');

    this.handleApplyVoucher = this.handleApplyVoucher.bind(this);

    //#endregion custom eligibility form
  }

  init() {
    //#region regular form

    this.institutionSelect.addEventListener(
      'change',
      this.handleInstitutionChange,
    );
    this.termSelect.addEventListener('change', this.handleTermChange);
    this.examSelect.addEventListener('choice', this.handleExamChange);
    if (this.isAdminSelect) {
      this.examTypeSelect.addEventListener('change', this.handleExamTypeChange);
    }
    this.submitButton.addEventListener('click', this.handleSubmit);

    //#endregion regular form

    //#region custom eligibility form

    this.applyVoucherButton.addEventListener('click', this.handleApplyVoucher);
    try {
      const customEligibilityInstitutionsData = document
        .getElementById('custom-eligibility-institutions')
        .getAttribute('data');
      this.#customEligibilityInstitutionsIds = JSON.parse(
        customEligibilityInstitutionsData,
      ).map((id) => id.toString());
    } catch (_) {}

    //#endregion custom eligibility form

    this.handleInstitutionChange();
  }

  //#region getters

  /** @returns {string} */
  get selectedUser() {
    return document
      .querySelector('[data-element="exam-select"]')
      .getAttribute('data-user');
  }

  /** @returns {boolean} */
  get isAdminSelect() {
    return this.examTypeSelect !== null;
  }

  /** @returns {HTMLOptionElement} */
  get selectedInstitution() {
    return this.institutionSelect.options[this.institutionSelect.selectedIndex];
  }

  /** @returns {HTMLOptionElement} */
  get selectedExamType() {
    return this.examTypeSelect.options[this.examTypeSelect.selectedIndex];
  }

  /** @returns {HTMLOptionElement} */
  get selectedExam() {
    return this.examSelect.options[this.examSelect.selectedIndex];
  }

  /** @returns {boolean} */
  get isCSU() {
    return (
      this.selectedInstitution.innerHTML === 'California Southern University' ||
      this.selectedInstitution.value === 4 // TODO: Resolve warning "Condition is always false since types 'string' and 'number' have no overlap".
    );
  }

  //#endregion getters

  //#region public methods

  /**
   * Handle a change to the 'Institution' <select>.
   * Set the 'exam type' to classic first.
   * If the value is null, reset all <select> to defaults.
   * If the value is CSU, fetch CSU exams.
   * Otherwise, proceed to fetching the relevant Terms.
   */
  handleInstitutionChange() {
    this.#reset();

    const selectedInstitution = this.selectedInstitution.value;
    if (selectedInstitution === '') {
      // if user selects 'Please Select':
      this.#toggleTermRow('flex');
    } else if (
      this.#customEligibilityInstitutionsIds.includes(selectedInstitution)
    ) {
      this.#displayCustomEligibilityForm();
    } else if (this.isCSU) {
      // handle CSU
      this.handleCSU();
    } else {
      // handle all other schools
      this.examChoice.destroy();
      this.examChoice = this.#createChoices(this.examSelect, 'exam-select');
      this.examChoice.disable();
      this.#enableExamTypeSelect();
      this.fetchTerms().then(this.setTerms).then(this.toggleTerms);
    }
  }

  /**
   * Handle a change to the 'ExamType' <select>.
   * Set the term select tag to 'Please Select' first.
   * Depending on exam type, toggle term row on or off
   * Fetch, set and enable exams
   */
  handleExamTypeChange() {
    if (!this.isAdminSelect) return;

    const examType = this.selectedExamType.value;

    this.#resetExamOptions();

    switch (examType) {
      case 'bluebird':
        this.#toggleTermRow('none');
        this.fetchExams().then(this.setExams).then(this.toggleExams);
        break;
      case 'classic':
        this.#toggleTermRow('flex');
        this.fetchTerms().then(this.setTerms).then(this.toggleTerms);
        break;
    }
  }

  /**
   * Handle a change to the 'Term' <select>.
   * If selecting a term, fetch, set and enable exams
   * If deselecting a term set exams 'Please select'
   * @param {Event} event
   */
  handleTermChange(event) {
    this.#resetExamOptions();
    this.selectedTerm = event.target;
    if (this.selectedTerm.value) {
      this.fetchExams().then(this.setExams).then(this.toggleExams);
    }
  }

  /**
   * Handle a change to the 'Exam' <select>.
   * Toggle submit button on or off based on selected value
   * @param {CustomEvent<{ choice: SelectChoice }>} event
   */
  handleExamChange(event) {
    const value = event.detail.choice.value;
    this.toggleSubmitButton(!!value);
  }

  handleCSU() {
    this.examChoice.destroy();
    this.examChoice = this.#createChoices(this.examSelect, 'exam-select');
    this.#setExamChoices(this.examPlaceholder);
    this.examChoice.setChoiceByValue('examPlaceholder');
    this.#toggleExamTypeRow('none');
    this.#toggleTermRow('none');
    this.fetchExams().then(this.setExams).then(this.toggleExams);
  }

  /**
   * Handle the search event
   * This function handles the search event from choices. It call's fetchExams
   * with the user input and sets the choices with the response
   */
  handleSearch() {
    const searchQuery = document.querySelector('input.choices__input').value;
    this.populateExamDropdown(searchQuery);
  }

  /**
   * Fetches terms asynchronously from a specified URL
   * @returns {Promise<Term[]>} A promise that resolves with the JSON content of the fetched terms.
   */
  async fetchTerms() {
    this.#toggleTermRow('flex');
    const response = await fetch(this.#termUrl(), {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
      },
    });

    return response.json();
  }

  /**
   * Create and append options to the term select tag
   * @param {Term[]} terms
   * @returns {Promise<Term[]>}
   */
  setTerms(terms) {
    return new Promise((resolve) => {
      this.resetTermOptions();
      if (terms.length < 1) {
        this.#setTermChoices(this.noTerms);
        this.termChoice.setChoiceByValue('noTerms');
      } else {
        this.#setTermChoices(terms);
      }
      resolve(terms);
    });
  }

  /**
   * @param {Term[]} terms
   */
  toggleTerms(terms) {
    terms.length > 0 ? this.termChoice.enable() : this.termChoice.disable();
  }

  /**
   * Fetches exams asynchronously based on the provided search parameters.
   * @param {string?} searchParam The search parameter to filter exams.
   * @returns {Promise<Exam[]>} A promise that resolves with the JSON content of the fetched exams.
   */
  async fetchExams(searchParam) {
    const response = await fetch(this.#examUrl(searchParam), {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
      },
    });

    return response.json();
  }

  /**
   * Create and append exams to the exam select tag
   * @param {Exam[]} exams
   * @returns {Promise<Exam[]>}
   */
  setExams(exams) {
    return new Promise((resolve) => {
      if (exams.length < 1) {
        this.#setExamChoices(this.noExams);
        this.examChoice.setChoiceByValue('noExams');
      } else {
        this.#setExamChoices(exams);
      }
      resolve(exams);
    });
  }

  /**
   * @param {Exam[]} exams
   */
  toggleExams(exams) {
    exams.length > 0 ? this.examChoice.enable() : this.examChoice.disable();
  }

  /**
   * @param {string} query
   */
  populateExamDropdown(query) {
    this.fetchExams(query).then((exams) => {
      this.#setExamChoices(exams);
      this.examChoice.unhighlightAll();
    });
  }

  /**
   * Toggles the submit button.
   * @param {boolean} toggle The display status
   */
  toggleSubmitButton(toggle) {
    this.submitButton.disabled = !toggle;
  }

  /**
   * Handle a click on the Submit button
   * Gets the base url from the button's data-url attribute
   * Since we fetch CSU iterations from a website CSU provides,
   * the iterations have no iteration_id, so we build a
   * special params object if the selected institution is CSU
   * Otherwise, we build params that only contain the iteration_id.
   * Depending on the institution, the url will either already
   * have query params or not. If it does, we add a query string
   * to it with a '&'. We use a '?' otherwise.
   * @param {MouseEvent} event
   */
  handleSubmit(event) {
    event.preventDefault();

    const target = /** @type {HTMLButtonElement} */ event.target;
    let [url, query] = target.getAttribute('data-url').split('?');

    let params;
    if (this.isCSU) {
      params = {
        exam_name: this.selectedExam.innerHTML,
        course_number: this.selectedExam.getAttribute('data-course-number'),
        institution_id: this.selectedInstitution.value,
        term_id: this.selectedTerm.value,
      };
    } else {
      const iterationId =
        this.selectedExam.value !== 'examPlaceholder'
          ? this.selectedExam.value
          : this.examInput.getAttribute('data-exam_id');

      params = {
        iteration_id: iterationId,
      };
    }

    const queryAddition = this.#toQuery(params);
    url = query
      ? `${url}?${query}&${queryAddition}`
      : `${url}?${queryAddition}`;

    window.location.href = url;
  }

  //#endregion public methods

  //#region private methods

  #reset() {
    this.#resetFormsDisplay();
    this.#resetExamTypeOptions();
    this.resetTermOptions();
    this.#resetExamOptions();
  }

  #resetFormsDisplay() {
    this.regularForm.classList.remove('d-none');
    this.customEligibilityForm.classList.add('d-none');
  }

  /**
   * Reset the 'exam-type' <select>.
   * Set the value back to 'Classic'.
   * Disable the <select>.
   */
  #resetExamTypeOptions() {
    if (this.isAdminSelect) {
      this.#toggleExamTypeRow('flex');
      this.examTypeSelect.value = 'classic';
      this.examTypeSelect.disabled = true;
    }
  }

  /**
   * Reset 'term' <select>.
   * Clear any options within the <select>.
   * Disable the <select>.
   */
  resetTermOptions() {
    this.selectedTerm = { value: '' };
    this.#disableTermSelect();
    this.#resetExamOptions();
  }

  /**
   * Reset 'exam' <select>.
   * Clear any options within the <select>.
   * Update the 'preview' text for the <select>.
   * Disable the <select>.
   */
  #resetExamOptions() {
    this.#disableExamSelect();
    this.toggleSubmitButton(false);
  }

  /**
   * @param {Term[]} choicesIn
   */
  #setTermChoices(choicesIn) {
    if (choicesIn.length > 0) {
      this.termChoice.setChoices(
        this.#buildTermChoices(choicesIn),
        'value',
        'label',
        true,
      );
    } else {
      this.termChoice.clearStore();
    }
  }

  /**
   * @param {Term[]} choices
   * @returns {SelectChoice[]}
   */
  #buildTermChoices(choices) {
    return choices.map((choice) => ({
      value: choice.id ? choice.id.toString() : choice.name.toString(),
      label: choice.name,
    }));
  }

  /**
   * Sets the choices for examSelect
   * This takes in an array of choices(options) and sets the choices
   * on the examChoice object (examSelect)
   * @param {Exam[]} choicesIn
   */
  #setExamChoices(choicesIn) {
    if (choicesIn.length > 0) {
      this.examChoice.setChoices(
        this.#buildExamChoices(choicesIn),
        'value',
        'label',
        true,
      );
    } else {
      this.examChoice.clearStore();
    }
  }

  /**
   * Build choices
   * This takes in an array of options and transforms
   * them into the desired structure with the correct
   * key: value pairs
   * @param {Exam[]} choices
   * @returns {SelectChoice[]}
   */
  #buildExamChoices(choices) {
    return choices.map((choice) => ({
      value: (choice.id || choice.name).toString(),
      label: (choice.name || choice.course_number)
        .toString()
        .replace(/(<([^>]+)>)/gi, ''),
    }));
  }

  /**
   * Generates the URL for fetching terms.
   * @returns {string} The generated URL for fetching terms.
   */
  #termUrl() {
    if (this.isAdminSelect) {
      const params = {
        user: this.selectedUser,
        institution_id: this.selectedInstitution.value,
        type: this.isAdminSelect ? this.selectedExamType.value : null,
      };

      return `/app/terms?${this.#toQuery(params)}`;
    } else {
      return `/students/app/institutions/${this.selectedInstitution.value}/terms`;
    }
  }

  /**
   * Generates the URL for fetching exams based on the provided search parameters.
   * @param {string?} searchParam The search parameter to filter exams.
   * @returns {string} The generated URL for fetching exams.
   */
  #examUrl(searchParam) {
    if (this.isAdminSelect) {
      const params = {
        user: this.selectedUser,
        institution_id: this.selectedInstitution.value,
        type: this.isAdminSelect ? this.selectedExamType.value : null,
        term_id: this.selectedTerm.value,
        query: searchParam || '',
      };

      return `/app/iterations?${this.#toQuery(params)}`;
    } else {
      const params = {
        term_id: this.selectedTerm.value,
        exclude_auto_exams: window.isChromeExtension,
        query: searchParam || '',
      };

      return `/students/app/institutions/${
        this.selectedInstitution.value
      }/iterations?${this.#toQuery(params)}`;
    }
  }

  #enableExamTypeSelect() {
    if (this.isAdminSelect) {
      this.examTypeSelect.disabled = false;
    }
  }

  #disableTermSelect() {
    this.termChoice.clearInput();
    this.#setTermChoices(this.termPlaceholder);
    this.termChoice.setChoiceByValue('termPlaceholder');
    this.termChoice.disable();
  }

  #disableExamSelect() {
    this.examChoice.clearInput();
    this.#setExamChoices(this.examPlaceholder);
    this.examChoice.setChoiceByValue('examPlaceholder');
    this.examChoice.disable();
  }

  /**
   * Toggles the 'Exam Type' row.
   * @param {string} style The display style of the HTML element ('flex' | 'none').
   */
  #toggleExamTypeRow(style) {
    if (this.isAdminSelect) {
      document.querySelector('[data-behavior="exam-type"]').style.display =
        style;
    }
  }

  /**
   * Toggles the 'Term' row.
   * @param {string} style The display style of the HTML element ('flex' | 'none').
   */
  #toggleTermRow(style) {
    document.querySelector('[data-behavior="term"]').style.display = style;
  }

  /**
   * Builds a query string using a JSON object.
   * @param params The JSON object.
   * @returns {string}
   */
  #toQuery(params) {
    return Object.keys(params)
      .map((key) => key + '=' + encodeURIComponent(params[key]))
      .join('&');
  }

  /**
   * Creates choices for the given `<select>` element and its identifying class.
   * @note This creates a new instance of {@link Choices}, adds event listeners,
   *   creates templates, and renders the {@link Choices} element to the DOM.
   * @param {HTMLSelectElement} element
   * @param {'term-select' | 'exam-select'} classIdentifier
   * @returns {Choices}
   */
  #createChoices(element, classIdentifier) {
    /** @type {Partial<Choices.Options>} */
    const config = {
      addItems: false,
      removeItems: false,
      searchResultLimit: 100,
      shouldSort: false,
    };

    // Override user-facing text with translations from i18n files
    config.loadingText = polyglot.t('loading');
    config.itemSelectText = polyglot.t('click_to_select');
    switch (classIdentifier) {
      case 'term-select':
        config.searchPlaceholderValue = polyglot.t('search_terms');
        config.noResultsText = polyglot.t('no_active_terms');
        config.noChoicesText = polyglot.t('no_terms_choices');
        break;
      case 'exam-select':
        config.searchPlaceholderValue = polyglot.t('search_exams');
        config.noResultsText = polyglot.t('no_active_exams');
        config.noChoicesText = polyglot.t('no_exams_choices');
        break;
    }

    // Override templates to resolve accessibility issues
    config.callbackOnCreateTemplates = () => {
      const defaultTemplates = Choices.defaults.templates;

      const labelId = `${classIdentifier}-label`;
      const dropdownId = `${classIdentifier}-dropdown`;

      return /** @type {Choices.Templates} */ ({
        containerOuter(...args) {
          const div = defaultTemplates.containerOuter.call(choices, ...args);

          div.setAttribute('aria-controls', dropdownId);
          div.setAttribute('aria-labelledby', labelId);

          return div;
        },

        containerInner(...args) {
          const div = defaultTemplates.containerInner.call(choices, ...args);

          div.classList.add('form-control');
          div.classList.add(classIdentifier);

          return div;
        },

        itemList(...args) {
          const div = defaultTemplates.itemList.call(choices, ...args);

          div.setAttribute('aria-live', 'assertive');

          return div;
        },

        item(...args) {
          const div = defaultTemplates.item.call(choices, ...args);

          div.removeAttribute('aria-selected');

          return div;
        },

        dropdown(...args) {
          const div = defaultTemplates.dropdown.call(choices, ...args);

          div.id = dropdownId;

          const observer = new MutationObserver((mutations) => {
            mutations.forEach(({ target, attributeName }) => {
              target.removeAttribute(attributeName);
            });
          });

          observer.observe(div, { attributeFilter: ['aria-expanded'] });

          return div;
        },
      });
    };

    const choices = new Choices(element, config);

    return choices;
  }

  //#endregion private methods

  //#region custom eligibility form

  /**
   * Handles "Apply Voucher" button logic.
   * @returns {Promise<void>}
   */
  async handleApplyVoucher() {
    this.#resetCustomEligibilityForm();
    const voucher = this.voucherInput.value;

    this.voucherInput.readOnly = true;
    this.toggleSubmitButton(false);
    this.#toggleLoadingSpinner(true);

    try {
      const response = await fetch('/internal/eligibilities/validate', {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
        body: JSON.stringify({ voucher: voucher.trim() }),
      });

      this.#toggleLoadingSpinner(false);

      if (!response.ok) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('An error occurred when validating the voucher code.');
      }

      const data = /** @type {Grant} */ await response.json();

      if (!data.valid) {
        this.#displayVoucherResultMessage(false, data.message_short);
        return;
      }

      if (!data.iteration || !data.exam || !data.department) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Missing iteration, exam, or department information.');
      }

      this.departmentInput.value = data.department.name;
      this.examInput.value = data.exam.name;
      this.examInput.setAttribute('data-exam_id', String(data.iteration.id));

      this.#displayVoucherResultMessage(true, polyglot.t('voucher_applied'));
      this.toggleSubmitButton(true);
    } catch (error) {
      this.#displayVoucherResultMessage(false, polyglot.t('unknown_error'));
      console.error(error);
    } finally {
      this.voucherInput.readOnly = false;
    }
  }

  #resetCustomEligibilityForm() {
    this.voucherInput.classList.remove('is-invalid', 'is-valid');
    this.resultMessage.textContent = '';
    this.departmentInput.value = '';
    this.examInput.value = '';
    this.toggleSubmitButton(false);
  }

  #displayCustomEligibilityForm() {
    this.regularForm.classList.add('d-none');
    this.customEligibilityForm.classList.remove('d-none');
  }

  /**
   * Displays the given voucher result message.
   * @param {boolean} isValid Whether the voucher was found to be valid.
   * @param {string} message The result message to be displayed.
   */
  #displayVoucherResultMessage(isValid, message) {
    if (isValid) {
      this.resultMessage.classList.remove('text-danger');
      this.resultMessage.classList.add('text-success');
      this.resultMessage.textContent = message;
      this.voucherInput.classList.remove('is-invalid');
      this.voucherInput.classList.add('is-valid');
    } else {
      this.resultMessage.classList.remove('text-success');
      this.resultMessage.classList.add('text-danger');
      this.resultMessage.textContent = message;
      this.voucherInput.classList.remove('is-valid');
      this.voucherInput.classList.add('is-invalid');
    }
  }

  /**
   * Toggles the loading spinner when the user submits a voucher.
   * @param {boolean} toggle The display status.
   */
  #toggleLoadingSpinner(toggle) {
    if (toggle) {
      this.applyVoucherButton.classList.add('d-none');
      this.loadingButton.classList.remove('d-none');
    } else {
      this.loadingButton.classList.add('d-none');
      this.applyVoucherButton.classList.remove('d-none');
    }
  }

  //#endregion custom eligibility form
}

export default ExamSelect;
