import axios from 'axios';
import Toast from '../../../../src/utils/Toast';
import csrfToken from '../../../../src/utils/csrf';
import CollapsibleCard from '../CollapsibleCard';
import ProctorControlButton from './ProctorControlButton';
import ProctorControlConfirmationButton from './ProctorControlConfirmationButton';

class ProctorControls extends React.Component {
  constructor(props) {
    super(props);

    this.isWatcherWindow = this.props.is_watcher_window;
    this.state = {
      isBtnDisabled: false,
      activeButtons: [],
      maxButtonsPerRow: this.isWatchWindow ? 2 : 4,
      triggeredAction: null,
      btnConfig: [
        {
          id: 'generic_proctor_controls_start',
          name: 'Start',
          className: 'btn-success',
          seq: 1,
          type: 'button',
          action: 'Started',
          tooltipText: 'will continue the exam session after a stop.',
        },
        {
          id: 'generic_proctor_controls_pause',
          name: 'Pause',
          className: 'btn-warning',
          seq: 2,
          type: 'button',
          action: 'Paused',
          tooltipText:
            'will temporarily halt the exam, and the test driver will remain open and active.',
        },
        {
          id: 'generic_proctor_controls_stop',
          name: 'Stop',
          className: 'btn-danger',
          seq: 3,
          type: 'button',
          action: 'Stopped',
          tooltipText:
            'triggers the test driver to shut down and the test-taker and the Proctor will need to reauthenticate the test-taker.',
        },
        {
          id: 'generic_proctor_controls_resume',
          name: 'Resume',
          className: 'btn-primary',
          seq: 4,
          type: 'button',
          action: 'Resumed',
          tooltipText: 'will continue the exam session after a pause.',
        },
        {
          id: 'generic_proctor_controls_terminate',
          name: 'Terminate',
          className: 'btn-danger',
          seq: 5,
          type: 'confirm',
          action: 'Terminated',
          tooltipText:
            'will end the exam session, close the test driver, and the appointment cannot be recovered by the test-taker.',
        },
      ],
    };
  }

  componentDidMount() {
    this.setActiveButtons();
  }

  renderButtons = () => {
    const numberOfRows = Math.ceil(
      this.state.activeButtons.length / this.state.maxButtonsPerRow,
    );
    const rowContent = [];
    for (let i = 1; i <= numberOfRows; i++) {
      const buttonElements = [];
      const itr =
        this.state.activeButtons.length <= this.state.maxButtonsPerRow * i
          ? this.state.activeButtons.length
          : this.state.maxButtonsPerRow * i;
      for (let y = (i - 1) * this.state.maxButtonsPerRow; y < itr; y++) {
        const buttonConfig = this.state.activeButtons[y];
        const buttonElement =
          buttonConfig.type === 'confirm' ? (
            <ProctorControlConfirmationButton
              isBtnDisabled={this.state.isBtnDisabled}
              key={buttonConfig.id}
              id={buttonConfig.id}
              className={buttonConfig.className}
              name={buttonConfig.name}
              action={buttonConfig.action}
              tooltipText={buttonConfig.tooltipText}
              publishMessage={this.publishMessage}
            />
          ) : (
            <ProctorControlButton
              isBtnDisabled={this.state.isBtnDisabled}
              key={buttonConfig.id}
              id={buttonConfig.id}
              className={buttonConfig.className}
              name={buttonConfig.name}
              action={buttonConfig.action}
              tooltipText={buttonConfig.tooltipText}
              publishMessage={this.publishMessage}
            />
          );
        buttonElements.push(buttonElement);
      }
      rowContent.push(
        <div
          key={`proctor_controls_container_${i}`}
          className="d-flex flex-row justify-content-between"
        >
          {buttonElements}
        </div>,
      );
    }
    return rowContent;
  };

  setActiveButtons = () => {
    const { active_buttons } = this.props;
    if (active_buttons) {
      const activeButtons = this.state.btnConfig
        .map((cfg) => {
          return { ...cfg, active: active_buttons.includes(cfg.id) };
        })
        .filter((val) => val.active);
      if (activeButtons.length > 0) {
        this.setState({ activeButtons });
      }
    }
  };

  publishMessage = (fieldName, action, name) => {
    // disabled all the buttons
    this.setState({ isBtnDisabled: true });

    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      },
    };

    const body = {
      fulfillment_id: this.props.fulfillment_id,
      platform_code: 'test-driver-actions',
      delivery_field: fieldName,
    };

    axios
      .post(
        `${window.location.origin}/api/nats/publish`,
        JSON.stringify(body),
        headers,
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ triggeredAction: action });
          new Toast().success({
            message: `The exam was successfully ${action}`,
          });
        } else {
          new Toast().danger({
            message: `Something went wrong; the test driver DID NOT ${name} the exam.`,
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        new Toast().danger({
          message: `Something went wrong; the test driver DID NOT ${name} the exam.`,
        });
      })
      .finally(() => {
        // enable buttons
        this.setState({ isBtnDisabled: false, triggeredAction: null });
      });
  };

  render() {
    if (this.state.activeButtons.length > 0) {
      if (!this.isWatcherWindow) {
        return (
          <CollapsibleCard headerText="Test Driver Actions">
            <div
              className={`card-body ${this.state.triggeredAction ? 'pt-1' : ''}`}
            >
              {this.state.triggeredAction && (
                <span>
                  This exam is currently
                  <strong className="pl-1">{this.state.triggeredAction}</strong>
                </span>
              )}
              {this.renderButtons()}
            </div>
          </CollapsibleCard>
        );
      } else {
        return this.renderButtons();
      }
    }
  }
}

export default ProctorControls;
