import { useEffect } from 'react';

import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';

const AutoWaitingCard = ({
  id,
  icon,
  iconPrefix,
  title,
  bodyText,
  buttonText,
  precheckData,
  sendEvent,
  broadcastMessage,
  handleBeginExam,
}) => {
  const deskImg = precheckData.images.deskImg;
  const supportImage = precheckData.images.extensionSupportImg;
  const isExtension = precheckData.extensionOverride;
  const supportText = isExtension
    ? polyglot.t('prechecks_waitingcard_auto_text_chrome_extension')
    : bodyText;

  const handleBeginExamClick = () => {
    sendEvent('Event::BeginExam');
    handleBeginExam();
  };

  useEffect(() => {
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'open_integrated_lmi_chat',
      data: {},
    });
  }, []);

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={deskImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />

      <PrechecksBodyText bodyText={supportText} />
      {isExtension && (
        <div className="mb-4">
          <img
            src={supportImage?.src}
            alt={supportImage?.altText}
            height="300"
          />
        </div>
      )}

      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleBeginExamClick}
      />
    </div>
  );
};

AutoWaitingCard.defaultProps = {
  title: polyglot.t('prechecks_waitingcard_auto_title'),
  icon: 'fa-check-circle',
  iconPrefix: 'fal',
  statusColor: 'primary',
  bodyText: polyglot.t('prechecks_waitingcard_auto_text'),
  buttonText: polyglot.t('prechecks_waitingcard_auto_button'),
};

export default AutoWaitingCard;
