import VideoPlayerControllerCombined from '../../components/VideoPlayer/VideoPlayerController';
import VideoPlayerControllerLegacy from '../../components/VideoPlayerLegacy/VideoPlayerController';

class VideoPlayerRenderer {
  constructor() {
    this.isLegacy = document.querySelector(
      '[data-element="video-player-legacy"]'
    );
    this.isCombined = document.querySelector(
      '[data-element="video-player-combined"]'
    );
    this.playerContainer =
      this.isLegacy === null ? this.isCombined : this.isLegacy;
  }

  init(isWatcher) {
    if (this.playerContainer) {
      const {
        examStarted,
        examCompleted,
        fulfillmentId,
        userId,
        userUuid,
        streamHost,
        spinnerPath,
        multiPart,
        accommodations,
        durationModifierAccommodation,
        integrityBreach,
        eventAlertConfigs,
        prechecksCompletedAt,
        iceServers,
        duration,
        showStudentStatus,
        sessionUuid,
        raiseHand,
        managerRequest,
        allowedResources,
        otherResources,
        eventsUrl,
        incidentSubtypes,
        showScreenRecording,
        videoLayoutSettings,
        videoService,
        region,
        externalCameraEnabled,
        enableScreenObfuscation,
        videoRecordingDisabled
      } = JSON.parse(this.playerContainer.dataset.props);

      const videoPlayerLegacyController = new VideoPlayerControllerLegacy(
        examStarted,
        examCompleted,
        fulfillmentId,
        userId,
        userUuid,
        streamHost,
        spinnerPath,
        isWatcher,
        multiPart,
        accommodations,
        durationModifierAccommodation,
        integrityBreach,
        eventAlertConfigs,
        prechecksCompletedAt,
        iceServers,
        duration,
        showStudentStatus,
        sessionUuid,
        raiseHand,
        managerRequest,
        allowedResources,
        otherResources,
        eventsUrl,
        incidentSubtypes,
        showScreenRecording,
        videoLayoutSettings,
        videoService,
        region,
        externalCameraEnabled,
        videoRecordingDisabled
      );
      const videoPlayerCombinedController = new VideoPlayerControllerCombined(
        examStarted,
        examCompleted,
        fulfillmentId,
        userId,
        userUuid,
        streamHost,
        spinnerPath,
        isWatcher,
        multiPart,
        accommodations,
        durationModifierAccommodation,
        integrityBreach,
        eventAlertConfigs,
        prechecksCompletedAt,
        iceServers,
        duration,
        showStudentStatus,
        sessionUuid,
        raiseHand,
        managerRequest,
        allowedResources,
        otherResources,
        eventsUrl,
        incidentSubtypes,
        showScreenRecording,
        videoLayoutSettings,
        videoService,
        region,
        externalCameraEnabled,
        enableScreenObfuscation,
        videoRecordingDisabled
      );
      const videoPlayerController = this.isLegacy
        ? videoPlayerLegacyController.init(this.playerContainer)
        : videoPlayerCombinedController.init(this.playerContainer);
      videoPlayerController;
    }
  }
}

// create an id per tab in browser or use the id from the session storage(for reload/refresh events).
// this tab id is passed to create the cjs instance using Copernicus Helper
document.addEventListener("turbolinks:load", () => {
  if (window.sessionStorage.tabId) {
    window.tabId = window.sessionStorage.tabId;
    window.sessionStorage.removeItem("tabId");
  } else if (!window.tabId) {
    window.tabId = Math.floor(Math.random() * 1000000);
  }
});

// store the id for the tab in session storage for reload/refresh event
window.addEventListener("beforeunload",  () => {
  window.sessionStorage.tabId = window.tabId;
});

// store the id for the tab in session for turbolink events
window.addEventListener("turbolinks:before-visit",  () => {
  window.sessionStorage.tabId = window.tabId;
});

export default VideoPlayerRenderer;
