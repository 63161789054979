import { useEffect } from 'react';
import axios from 'axios';
import csrfToken from '../../src/utils/csrf';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';

const GuidedLaunchWaitingCard = ({
  id,
  precheckData,
  icon,
  iconPrefix,
  title,
  bodyText,
  buttonText,
  sendEvent,
  broadcastMessage,
  handleBeginExam,
}) => {
  const deskImg = precheckData.images.deskImg;
  const isExtension = precheckData.extensionOverride;

  useEffect(() => {
    sendEvent('Event::GuidedLaunchBeginExamLoaded');
  }, []);

  const handleBeginExamClick = () => {
    sendEvent('Event::BeginExam');
    handleBeginExam();
  };

  useEffect(() => {
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'open_integrated_lmi_chat',
      data: {},
    });
  }, []);

  useEffect(() => {
    axios
      .post(
        `${window.location.origin}/api/secure_browser/events/session_awaiting_verification`,
        {
          uuid: precheckData.fulfillment.uuid,
          event: { created_by_id: precheckData.user.id },
        },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getBodyText = () => {
    if (precheckData.integratedLmiChat) {
      return polyglot.t(
        'prechecks_guidedlaunch_integrated_chat_waitingcard_text',
      );
    } else {
      return polyglot.t('prechecks_guidedlaunch_waitingcard_text');
    }
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={deskImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />

      {precheckData.integratedLmiChat && (
        <PrechecksBodyText
          bodyText={polyglot.t(
            'prechecks_guidedlaunch_waitingcard_proctor_text',
          )}
        />
      )}
      {!precheckData.integratedLmiChat && (
        <PrechecksBodyText bodyText={bodyText} />
      )}
      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleBeginExamClick}
      />
    </div>
  );
};

GuidedLaunchWaitingCard.defaultProps = {
  title: polyglot.t('prechecks_guidedlaunch_waitingcard_title'),
  bodyText: polyglot.t('prechecks_guidedlaunch_waitingcard_text'),
  icon: 'fa-check-circle',
  iconPrefix: 'fal',
  statusColor: 'primary',
  buttonText: polyglot.t('prechecks_guidedlaunch_waitingcard_button'),
};

export default GuidedLaunchWaitingCard;
