import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const PluginDevice = (props) => {
  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center">
          <h2>Plug In Device</h2>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="d-flex justify-content-center">
          <Button
            variant="primary"
            onClick={() => props.setCurrentStep('POSITION_PHONE')}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PluginDevice;
