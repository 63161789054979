import { useEffect } from 'react';
import axios from 'axios';
import csrfToken from '../../src/utils/csrf';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';

const GuidedLaunchWaitingForProctorCard = ({
  id,
  precheckData,
  icon,
  iconPrefix,
  title,
  bodyText,
  sendEvent,
  broadcastMessage,
}) => {
  const deskImg = precheckData.images.deskImg;

  useEffect(() => {
    sendEvent('Event::GuidedLaunchWaitingScreenLoaded');
  }, []);

  useEffect(() => {
    axios
      .post(
        `${window.location.origin}/api/secure_browser/events/session_awaiting_verification`,
        {
          uuid: precheckData.fulfillment.uuid,
          event: { created_by_id: precheckData.user.id },
        },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'open_integrated_lmi_chat',
      data: {},
    });
  }, []);

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={deskImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <PrechecksBodyText bodyText={bodyText} />
    </div>
  );
};

GuidedLaunchWaitingForProctorCard.defaultProps = {
  title: polyglot.t('prechecks_guidedlaunch_waiting_for_proctor_card_title'),
  bodyText: polyglot.t('prechecks_guidedlaunch_waiting_for_proctor_card_text'),
  icon: 'fa-check-circle',
  iconPrefix: 'fal',
  statusColor: 'primary',
};

export default GuidedLaunchWaitingForProctorCard;
