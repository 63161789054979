class WatcherPopout {
  constructor() {
    this.buttons = document.querySelectorAll('.js-watcher-popout');
  }

  init() {
    if(this.buttons) {
      this.buttons.forEach(button => {
        button.addEventListener('click', (event) => { this.handleClick(event) });
      })
    }
  }

  handleClick(event) {
    let url = null
    if(event.target.tagName === 'I') { url = event.target.parentElement.getAttribute('href') }
    else                             { url = event.target.getAttribute('href') }
    window.open(url, '_blank', 'height=525,width=384,noopener');
    event.preventDefault();
  }
}

export default WatcherPopout;
