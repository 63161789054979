import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import CameraSelector from './CameraSelector';
import ExamComplete from './ExamComplete';
import MediaPermission from './MediaPermission';
import PhonePosition from './PhonePosition';
import PluginDevice from './PluginDevice';
import SecondCameraSubscription from './EventConsumer';
import '../../stylesheets/components/second_camera/second_camera.scss';
import { StreamContext } from './contexts';
import enMessages from '../../../javascript/messages/en.json';
import frMessages from '../../../javascript/messages/fr.json';
import esMessages from '../../../javascript/messages/es.json';

import _ from 'lodash';
import { I18n } from 'react-polyglot';

const screens = [
  'MEDIA_PERMISSION',
  'PLUGIN_DEVICE',
  'POSITION_PHONE',
  'SELECT_CAMERA',
  'EXAM_IN_PROGRESS',
  'EXAM_COMPLETE',
];

const App = (props) => {
  const [currentStep, setCurrentStep] = useState(screens[0]);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [videoStream, setVideoStream] = useState(null);

  const locale =
    document.querySelector('span.user-data')?.getAttribute('locale') ||
    window.navigator.language ||
    'en';

  useEffect(() => {
    // subscribe to examComplete Event
    let parts = window.location.pathname.split('/');
    let fulfillmentId = parts[3];
    let secondCamSubscription = new SecondCameraSubscription(
      fulfillmentId,
      () => window.dispatchEvent(new Event('examComplete')),
    );
    secondCamSubscription.subscribe();

    // on unmount unsubscribe
    return () => {
      secondCamSubscription.unsubscribe();
    };
  }, []);

  // default messages are English
  let messages = { ...enMessages };

  if (locale.startsWith('fr')) {
    messages = _.merge(messages, frMessages);
  } else if (locale.startsWith('es')) {
    messages = _.merge(messages, esMessages);
  }

  let contextObj = {
    deviceList,
    setDeviceList,
    selectedDevice,
    setSelectedDevice,
    videoStream,
    setVideoStream,
  };

  return (
    <I18n locale={locale} messages={messages}>
      <StreamContext.Provider value={contextObj}>
        <Container fluid="md" id="main-container">
          {(() => {
            switch (currentStep) {
              case 'MEDIA_PERMISSION':
                return <MediaPermission setCurrentStep={setCurrentStep} />;
              case 'PLUGIN_DEVICE':
                return <PluginDevice setCurrentStep={setCurrentStep} />;
              case 'SELECT_CAMERA':
                return (
                  <CameraSelector
                    setCurrentStep={setCurrentStep}
                    region={props.region}
                  />
                );
              case 'POSITION_PHONE':
                return <PhonePosition setCurrentStep={setCurrentStep} />;
              case 'EXAM_COMPLETE':
                return <ExamComplete setCurrentStep={setCurrentStep} />;
              default:
                return null;
            }
          })()}
        </Container>
      </StreamContext.Provider>
    </I18n>
  );
};

export default App;
export { StreamContext };
