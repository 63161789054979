import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import parse from 'html-react-parser';
import { useTranslate } from 'react-polyglot';

import PhoneCoffeeMug from '../../../assets/images/second_camera/setup-phone.svg';

const PhonePosition = (props) => {
  const t = useTranslate();
  return (
    <>
      <Row className="mt-5 mt-landscape">
        <Col className="col-6 offset-3">
          <Image className="w-100 phone-coffee-img" src={PhoneCoffeeMug} />
        </Col>
        <div className="w-100"></div>
        <Col className="col-12">
          <h6 className="mt-4 mb-4 mt-landscape mb-landscape text-center">
            {t('phone_position.rotate_heading')}
          </h6>
          <p className="ml-3 mr-3">
            {parse(t('phone_position.wide_camera_angle'))}
          </p>
          <p className="ml-3 mr-3">
            {parse(t('phone_position.cup_position_workspace'))}
          </p>
        </Col>
      </Row>
      <div className="text-center sc-nav-container">
        <Button
          variant="secondary"
          className="mr-3"
          onClick={() => {
            props.setCurrentStep('MEDIA_PERMISSION');
          }}
        >
          {t('nav_button.back')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            props.setCurrentStep('SELECT_CAMERA');
          }}
        >
          {t('nav_button.next')}
        </Button>
      </div>
    </>
  );
};

export default PhonePosition;
