import ReactDOM from 'react-dom'
import FieldContainer from '../../components/Users/AdditionalEnrollments/Templates/FieldContainer'

// Enrollment Fields
//
// Responsible for the additional enrollment fields for users.
class EnrollmentFields {
  constructor() {
    this.mapEnrollmentFields()
  }

  init() {
    this.bindEventListeners()
  }

  bindEventListeners() {
    $(document).on('change', '.enrollment-fields .enroll-select', (e) => {
      this.fetchAdditionalEnrollmentFields(e);
    });

    // Initialize labelCount to 2 because the first select element for institutions is already present
    let labelCount = 2;
    $(document).on('cocoon:before-insert', function (e, insertedItem) {
      const item = $(insertedItem).find('.enroll-select');
      item.attr('aria-label', `institution ${labelCount}`);
      labelCount++;
    });
  }

  mapEnrollmentFields() {
    _.map($('.enrollment-fields'), (field) => {
      const $field = $(field);
      const $enrollSelect = $field.find(".enroll-select");
      // Skip if there is no select element.
      if (!$enrollSelect.length) {
        return;
      }

      const enrollSelectVal = $enrollSelect.val();
      if (!(enrollSelectVal.length > 0)) {
        return;
      }
      this.fetchAdditionalEnrollmentFields($enrollSelect);
    });
  }

  fetchAdditionalEnrollmentFields(e) {
    let $this;

    if (typeof e.currentTarget === 'undefined') {
      $this = $(e);
    } else {
      $this = $(e.currentTarget);
    }
    const $allContainers = $('.enrollment-fields');
    const $container = $this.closest('.enrollment-fields');
    const url = $this.find(':selected').data('additional-fields-url');
    const index = $allContainers.index($container);
    return $.getJSON(url, {}, (res) => {
      const $additionalField =
        $container.find('[data-behavior=additional-enrollment-fields]');

      let $preferredLanguageSection = $("#preferred-language-section");
      let preferredLanguageDropdown = document.getElementById(
          'user_preferred_language'
      );

      if (res.data.languages.length) {
        preferredLanguageDropdown.innerText = null;
        res.data.languages.forEach((language) => {
          let opt = document.createElement('option');
          opt.value = language.name;
          opt.innerHTML = language.name;
          if (language.name == 'English') {
            opt.selected = true;
          }
          preferredLanguageDropdown.appendChild(opt);
          $preferredLanguageSection.show();
        });
      } else {
        $preferredLanguageSection.hide();
      }

      if (res.data.additional_enrollment_fields.length) {
        // Hack to get the ID of the added Cocoon field
        // So the enrollment_attributes will be attached to the right nested select field for the
        // institution.
        const generatedId = parseInt($this.attr('id').split('user_enrollments_attributes_').pop().split('_institution_id')[0]);
        const data = {
          fields: res.data.additional_enrollment_fields,
          index: index,
          generatedId: generatedId
        };

        ReactDOM.render(<FieldContainer {...data} />, $additionalField.get(0))

        let valuesHash = $additionalField.data('values');

        _.each(valuesHash, function (values, key) {
          if (values.field) {
            if (values['type'] === 'date_select') {
              date = values.field.split('-')
              year = $("[data-field=" + key + "].year").val(date[0]);
              month = $("[data-field=" + key + "].month").val(date[1]);
              day = $("[data-field=" + key + "].day").val(date[2]);
            } else {
              $("[data-field=" + key + "]").val(values.field);
            }
          }

          if (values.require && values.field === null) {
            return $("[data-form-group=" + key + "] [data-type=error]").removeClass('d-none');
          }
        });

        return $additionalField.removeClass('d-none');
      } else {
        return $additionalField.html('');
      }
    });
  }
}

export default EnrollmentFields;
