class Countdown {
  constructor() {
    this.futureExams = document.querySelectorAll(
      '[data-countdown-available="true"]'
    );
    this.startUpButton = document.querySelector(
      '[data-behavior="start"]:not(.d-none)'
    );
    this.countdownContainer = document.querySelector(
      ".js-row-panel-countdown"
    )
    this.countdownText = $(".js-countdown-text");
    this.countdownClock = $(".js-countdown-clock");
    this.escalationMessage = $(".escalation-message");
    this.examsCounter = 0;
    this.closestReservation = document.querySelector(
      '[data-behavior="closest-reservation"]'
    );
    this.displayStartButton = this.displayStartButton.bind(this);
    this.displayTimeLeft = this.displayTimeLeft.bind(this);
  }

  init() {
    if (!this.closestReservation)
      $(".js-row-panel-countdown").remove();
    else if (this.startUpButton)
      this.displayStartButton(this.startUpButton);
    else
      this.startCountdown(this.closestReservation);
  }

  displayStartButton(startButton) {
    this.countdownText.html(`<strong>${polyglot.t('ready_to_go')}<strong>`);
    startButton.classList.remove("d-none");
    let startButtonClone = startButton.cloneNode(true);
    startButtonClone.classList.add("btn-start", "mt-3");
    this.countdownClock.html(startButtonClone);

    const examRow = startButton.closest('tr');
    if (examRow.getAttribute("data-escalated")) {
      this.escalationMessage.html(polyglot.t('escalated'));
    }
    if (startButton.text === "Reconnect") {
      let rescheduleButtons = examRow.querySelectorAll(
        '[data-behavior="reschedule"]'
      );
      [...rescheduleButtons].forEach(button => {
        button.classList.add("d-none");
      });
    }
    const expirationDate = examRow.getAttribute("data-expiration-time");
    const timeout = Math.round(expirationDate - Date.parse(new Date()));
    setTimeout(
      () => this.makeExamUnavailable(examRow, startButton, startButtonClone),
      timeout
    );
    setTimeout(() => this.startNextCountdown(), timeout);
  }

  makeExamUnavailable(examRow, startButton, startButtonClone) {
    startButtonClone.remove();
    if (examRow.getAttribute("data-enable-reschedule")) {
      startButton.remove();
      let rescheduleButtons = examRow.querySelectorAll(
        '[data-behavior="reschedule"]'
      );
      [...rescheduleButtons].forEach(button => {
        button.classList.remove("d-none");
      });
    } else {
      examRow.remove();
    }
  }

  startNextCountdown() {
    let nextExam = this.futureExams[this.examsCounter];
    if (nextExam) {
      this.startCountdown(nextExam);
    } else {
      const startButtons = document.querySelectorAll('[data-behavior="start"]');
      if (startButtons.length === 0) {
        $(".js-row-panel-countdown").remove();
      } else {
        this.displayStartButton(startButtons[0]);
      }
      this.displayBlankState();
    }
  }

  startCountdown(examRow) {
    if (examRow) {
      this.escalationMessage.html("");

      this.countdownText.html(`<strong>${polyglot.t('countdown_to_exam')}:</strong>`);
      this.countdownClock.html(`<div class="row h-100 align-items-center text-uppercase text-center">
                                  <span class="col-sm-3">
                                    <span class="h1 days"></span>
                                    <div>
                                      <small>${polyglot.t('datetime_prompts_days')}</small>
                                    </div>
                                  </span>
                                  <span class="col-sm-3">
                                    <span class="h1 hours"></span>
                                    <div>
                                      <small>${polyglot.t('datetime_prompts_hours')}</small>
                                    </div>
                                  </span>
                                  <span class="col-sm-3">
                                    <span class="h1 minutes"></span>
                                    <div>
                                      <small>${polyglot.t('datetime_prompts_minutes')}</small>
                                    </div>
                                  </span>
                                  <span class="col-sm-3">
                                    <span class="h1 seconds"></span>
                                    <div>
                                      <small>${polyglot.t('datetime_prompts_seconds')}</small>
                                    </div>
                                  </span>
                                </div>
                              `);
      const countdownDate = new Date(
        examRow.querySelector('[data-behavior="countdown-date"]').dataset["date"]
      );
      if (countdownDate > new Date()) {
        let serverTime = this.getTimestamp();
        this.displayTimeLeft(countdownDate, serverTime);
        const timeInterval = setInterval(() => {
          const timeLeft = this.displayTimeLeft(countdownDate, serverTime);
          serverTime = this.incrementServerTime(serverTime);
          if (timeLeft.total <= 0) {
            clearInterval(timeInterval);
            this.examsCounter += 1;
            const uuid = examRow.dataset["reservation"];
            const visibleExamRow = document.querySelector(`[data-reservation="${uuid}"]:not(.d-none)`);

            this.displayStartButton(
              visibleExamRow.querySelector('[data-behavior="d-nonestart"]')
            );
          }
        }, 1000);
      } else {
        this.examsCounter += 1;
        this.startNextCountdown();
      }
    }
  }

  displayBlankState() {
    if (document.querySelectorAll(".js-exam-row").length === 0) {
      const allowScheduling = $(".js-exams-table").data("allowing-schedule");
      const blankslate = `<tr>
          <td class="table-full" colspan="4">
            You currently do not have any sessions scheduled.
            ${this.renderAllowSchedule(allowScheduling)}
          </td>
        </tr>
        `;
      $(".js-exams-table tbody").html(blankslate);
    }
  }

  renderAllowSchedule(allowScheduling){
    if (allowScheduling) {
      return `Click "Schedule New Session" to add your next session.
      <a href="/students/exams/select" class="btn btn-secondary">
        Schedule New Session
      </a>`;
    } else { return ''}
  }
  displayTimeLeft(countdownDate, serverTime) {
    const t = this.getTimeRemaining(countdownDate, serverTime);
    $(".days").html(t.days);
    $(".hours").html(t.hours);
    $(".minutes").html(t.minutes);
    $(".seconds").html(t.seconds);
    return t;
  }

  getTimestamp() {
    const timestamp = this.countdownContainer.dataset.timestamp
    return Date.parse(timestamp)
  }

  incrementServerTime(t) {
    return t + 1000
  }

  getTimeRemaining(endtime, serverTime) {
    const t = Date.parse(endtime) - serverTime;
    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
      total: t == 0 ? '00' : t,
      days: days.toString().padStart(2, '0'),
      hours: hours == 0 ? '00' : ("0" + hours).slice(-2),
      minutes: minutes == 0 ? '00' : ("0" + minutes).slice(-2),
      seconds: seconds == 0 ? '00' : ("0" + seconds).slice(-2)
    };
  }
}

export default Countdown;
