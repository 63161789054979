import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import { useEffect } from 'react';
import axios from 'axios';
import csrfToken from '../../src/utils/csrf';

const LivePlusWaitingCard = ({
  id,
  icon,
  iconPrefix,
  precheckData,
  broadcastMessage,
}) => {
  const deskImg = precheckData.images.deskImg;

  useEffect(() => {
    axios
      .post(
        `${window.location.origin}/api/secure_browser/events/session_awaiting_verification`,
        {
          uuid: precheckData.fulfillment.uuid,
          event: { created_by_id: precheckData.user.id },
        },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'open_integrated_lmi_chat',
      data: {},
    });
  }, []);

  const paragraph2 = () => {
    return (
      <p>
        {polyglot.t('prechecks_waitingcard_liveplus_paragraph2_part1')}
        <a
          href="https://support.proctoru.com/hc/en-us/articles/13146310686349"
          target="_blank"
          className="precheck-a"
        >
          {polyglot.t('prechecks_waitingcard_liveplus_paragraph2_link')}
        </a>
        {polyglot.t('prechecks_waitingcard_liveplus_paragraph2_part2')}
      </p>
    );
  };

  const calculateBodyText = () => {
    if (precheckData.integratedLmiChat) {
      // if integrated chat is on, they can interact with the proctor just like Guided Launch
      return (
        <PrechecksBodyText
          bodyText={polyglot.t(
            'prechecks_guidedlaunch_waiting_for_proctor_card_text',
          )}
        />
      );
    } else {
      return (
        <>
          <PrechecksBodyText
            bodyText={polyglot.t('prechecks_waitingcard_liveplus_paragraph1')}
          />
          {paragraph2()}
          <PrechecksBodyText
            bodyText={polyglot.t('prechecks_waitingcard_liveplus_paragraph3')}
          />
          <PrechecksBodyText
            bodyText={polyglot.t('prechecks_waitingcard_liveplus_paragraph4')}
          />
        </>
      );
    }
  };

  const calculateTitle = () => {
    if (precheckData.integratedLmiChat) {
      // if integrated chat is on, they can interact with the proctor just like Guided Launch
      return polyglot.t('prechecks_guidedlaunch_waitingcard_title');
    } else {
      return polyglot.t('prechecks_waitingcard_liveplus_title');
    }
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={calculateTitle()}
        image={deskImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      {calculateBodyText()}
    </div>
  );
};

LivePlusWaitingCard.defaultProps = {
  title: polyglot.t('prechecks_waitingcard_liveplus_title'),
  icon: 'fa-check-circle',
  iconPrefix: 'fal',
  statusColor: 'primary',
  buttonText: polyglot.t('prechecks_waitingcard_liveplus_next_button'),
};

export default LivePlusWaitingCard;
