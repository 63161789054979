import React, { useState, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

/** @typedef {import('@stripe/stripe-js').PaymentMethodCreateParams} PaymentMethodCreateParams */
/** @typedef {import('@stripe/stripe-js').PaymentMethodCreateParams.BillingDetails} PaymentMethodCreateParams.BillingDetails */
/** @typedef {import('@stripe/stripe-js').StripeAddressElementChangeEvent} StripeAddressElementChangeEvent */
/** @typedef {import('@stripe/stripe-js').StripeElementsOptions} StripeElementsOptions */
/** @typedef {import('@stripe/stripe-js').StripeError} StripeError */

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const [message, setMessage] = useState(null);
  const [billingDetails, setBillingDetails] = useState(
    /** @type {PaymentMethodCreateParams.BillingDetails} */ ({}),
  );

  const disablePayment = !stripe || !elements || isLoading;

  /**
   * @param {StripeAddressElementChangeEvent} event
   */
  const handleAddressChange = (event) => {
    setBillingDetails(event.value);
  };

  /**
   * @param {StripeError} error
   */
  const handleError = (error) => {
    setIsLoading(false);
    setMessage(error.message);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show validation errors to the user so they can correct their payment details
      handleError(submitError);
      return;
    }

    // Create the ConfirmationToken using the details collected by the Payment Element
    // and additional shipping information
    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: billingDetails,
        },
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      return;
    }

    // TODO: finalize payment on the server with `confirmationToken`
    // https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#submit-payment
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // TODO: make this a toast or something better
    // TODO: redirect somewhere else?
    setMessage('Payment successful! 🎉');
    setIsComplete(true);
    setIsLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <AddressElement
          options={{ mode: 'billing' }}
          onChange={handleAddressChange}
        />

        {!isComplete && stripe && elements && (
          <button
            disabled={disablePayment}
            id="submit"
            className="mt-4 btn btn-primary w-100"
            style={{ cursor: disablePayment ? 'not-allowed' : 'pointer' }}
          >
            {isLoading
              ? // TODO: add a spinner and do localization
                'Submitting...'
              : 'Pay now'}
          </button>
        )}

        {/* TODO: show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
};

/**
 * @typedef {Object} StripePaymentFormProps
 * @property {string} stripePublishableKey
 * @property {number} amount
 * @property {string} commitUrl
 */

/**
 * @param {StripePaymentFormProps} props
 * @returns {React.ReactElement}
 */
export default function StripePaymentForm({ stripePublishableKey, amount }) {
  // Make sure to memoize the stripe promise so it's only created once and not on every render
  const stripePromise = useMemo(
    () => loadStripe(stripePublishableKey),
    [stripePublishableKey],
  );

  /** @type {StripeElementsOptions} */
  const options = {
    mode: 'payment',
    amount,
    currency: 'usd',
    appearance: {
      theme: 'stripe',
    },
  };

  return (
    <div className="card p-4">
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}
