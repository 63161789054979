import axios from 'axios';

import csrfToken from '../../src/utils/csrf';

const LanguageDropdown = ({ selectLanguage, languages, icon }) => {
  const handleLanguageDropbox = (lang) => {
    const url = `${window.location.origin}/account/change_language`;
    const data = { preferred_language: lang };
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      },
    };

    axios
      .patch(url, data, config)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="position-absolute dropdown" style={{ right: 0 }}>
      <button
        className="btn precheck-dark dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className={icon}></i>
        {selectLanguage}
      </button>
      <div className="dropdown-menu">
        {languages.map((language, index) => (
          <button
            key={index}
            value={language.name}
            className="dropdown-item precheck-dropdown-item"
            type="button"
            onClick={(e) => handleLanguageDropbox(e.target.value)}
          >
            {polyglot.t(`prechecks_language_dropdown_${language.locale}`)}
          </button>
        ))}
      </div>
    </div>
  );
};

LanguageDropdown.defaultProps = {
  selectLanguage: polyglot.t('prechecks_language_dropdown_select'),
  languages: [
    { name: 'prechecks_language_dropdown_en', locale: 'en' },
    { name: 'prechecks_language_dropdown_es', locale: 'es' },
    { name: 'prechecks_language_dropdown_fr_ca', locale: 'fr_ca' },
  ],
  icon: 'fal fa-globe',
};

export default LanguageDropdown;
