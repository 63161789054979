import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import { useEffect } from 'react';
import axios from 'axios';
import csrfToken from '../../src/utils/csrf';

const RecordPlusWaitingCard = ({
  id,
  icon,
  iconPrefix,
  title,
  bodyText,
  buttonText,
  precheckData,
  broadcastMessage,
  handleBeginExam,
}) => {
  const deskImg = precheckData.images.deskImg;

  const handleBeginExamClick = () => {
    sendEvent('Event::BeginExam');
    handleBeginExam();
  };

  useEffect(() => {
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'open_integrated_lmi_chat',
      data: {},
    });
  }, []);

  useEffect(() => {
    axios
      .post(
        `${window.location.origin}/api/secure_browser/events/session_awaiting_verification`,
        {
          uuid: precheckData.fulfillment.uuid,
          event: { created_by_id: precheckData.user.id },
        },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={deskImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <PrechecksBodyText bodyText={bodyText} />
      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleBeginExamClick}
      />
    </div>
  );
};

RecordPlusWaitingCard.defaultProps = {
  icon: 'fa-check-circle',
  statusColor: 'primary',
  iconPrefix: 'fal',
  title: polyglot.t('prechecks_waitingcard_recordplus_title'),
  bodyText: polyglot.t('prechecks_waitingcard_recordplus_text'),
  buttonText: polyglot.t('prechecks_waitingcard_recordplus_button'),
};

export default RecordPlusWaitingCard;
