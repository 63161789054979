import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Image from 'react-bootstrap/Image';
import { useEffect, useState, useContext, useRef } from 'react';
import { StreamContext } from './contexts';
import { copernicus } from './CopernicusHelper';

import SecondCameraExample from '../../../assets/images/second_camera/2nd-camera-example.svg';
import Disconnection from '../../../assets/images/second_camera/connection-error.svg';
import { getPrioritizedDeviceList } from './DeviceHelper';
import { useTranslate } from 'react-polyglot';

const CameraSelector = (props) => {
  window.addEventListener('examDisconnect', () => {
    setDisconnected(true);
  });

  const t = useTranslate();
  const [cameras, setCameras] = useState(['wide', 'telephoto', 'ultrawide']);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [examInProgress, setExamInProgress] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [cjsInstance, setCJSInstance] = useState(
    /** @type {import('@meazure/copernicusjs').default} */ (null),
  );
  const videoRef = useRef();
  const {
    deviceList,
    selectedDevice,
    videoStream,
    setVideoStream,
    setSelectedDevice,
  } = useContext(StreamContext);
  const firstUpdate = useRef(true);
  const aspectRatio = (16 / 9).toFixed(2);

  window.addEventListener('examComplete', async () => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
    void cjsInstance?.close();
    props.setCurrentStep('EXAM_COMPLETE');
  });

  window.addEventListener('examDisconnect', () => {
    setDisconnected(true);
  });

  useEffect(() => {
    document.body?.requestFullscreen?.().then(() => {
      window.screen.orientation?.lock?.('landscape').catch((error) => {
        console.log(error);
      });
    });
    setSelectedCamera(selectedDevice);
    videoRef.current.srcObject = videoStream;
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: selectedCamera,
          aspectRatio: {
            ideal: aspectRatio,
          },
        },
        audio: false,
      })
      .then((stream) => {
        setVideoStream(stream);
        setSelectedDevice(selectedCamera);
        videoRef.current.srcObject = stream;
      });
  }, [selectedCamera]);

  let goBack = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    props.setCurrentStep('POSITION_PHONE');
  };

  let goNext = async () => {
    const videoLayoutSettings = {
      videoLayout: document
        .querySelector('span.env-data')
        .getAttribute('videoLayout'),
      combinedStreamMaxBandwidth: document
        .querySelector('span.env-data')
        .getAttribute('combinedStreamMaxBandwidth'),
      combinedStreamFrameRate: document
        .querySelector('span.env-data')
        .getAttribute('combinedStreamFrameRate'),
      secondCameraMaxBandwidth: document
        .querySelector('span.env-data')
        .getAttribute('secondCameraMaxBandwidth'),
    };

    let parts = window.location.pathname.split('/');
    /** @type {import('@meazure/copernicusjs').default} */
    let cjs = await copernicus.getInstance(
      parts[3],
      videoRef.current,
      selectedCamera,
      props.region,
      videoLayoutSettings.videoLayout,
    );
    setCJSInstance(cjs);
    let iscjsInitialized = await cjs.initialize();
    if (iscjsInitialized) {
      void cjs.start(videoLayoutSettings);
      setExamInProgress(true);
    } else {
      console.warn('CJS failed to initialize');
    }
  };

  let classList = 'd-flex mt-1 justify-content-between';
  let prioritizedDeviceList = getPrioritizedDeviceList(
    deviceList.filter((d) => d.kind === 'videoinput'),
  );
  if (prioritizedDeviceList.length <= 1) {
    classList = 'd-flex mt-4 justify-content-center gap-bw-items';
  }
  if (examInProgress) {
    classList = 'd-flex mt-1 justify-content-center';
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="camera-holder">
          <Row className="d-flex flex-column">
            <Col className="d-flex justify-content-center col-12">
              {!examInProgress && !disconnected && (
                <h6 className="mt-4 mb-2 text-center">
                  {t('camera_selector.select_lens_heading')}
                </h6>
              )}
              {examInProgress && !disconnected && (
                <h6 className="mt-4 mb-4 text-center">
                  {t('camera_selector.back_to_desk_heading')}
                </h6>
              )}
              {examInProgress && disconnected && (
                <h6 className="mt-4 mb-4 text-center">
                  {t('camera_selector.cam_disconnected_heading')}
                </h6>
              )}
            </Col>
            <Col className="d-flex justify-content-center col-12">
              {!examInProgress && !disconnected && (
                <p className="ml-3 mr-3 text-center">
                  {t('camera_selector.secondary_cam_capture')}
                </p>
              )}
              {examInProgress && !disconnected && (
                <p className="ml-3 mr-3 text-center">
                  {t('camera_selector.continue_adjust_cam')}
                </p>
              )}
              {examInProgress && disconnected && (
                <p className="ml-3 mr-3 text-center">
                  {t('camera_selector.reach_proctor_support')}
                </p>
              )}
            </Col>
            <Col className="col-12">
              <Row>
                <Col className="col-5 d-flex flex-column">
                  {!disconnected && (
                    <>
                      <p className="sec-heading text-center">
                        {t('camera_selector.example')}
                      </p>
                      <Image className="w-100" src={SecondCameraExample} />
                    </>
                  )}
                  {disconnected && (
                    <>
                      <Image className="w-100" src={Disconnection} />
                    </>
                  )}
                </Col>
                <Col className="col-7 d-flex flex-column align-items-center">
                  <p className="sec-heading text-center">
                    {t('camera_selector.your_camera')}
                  </p>
                  <video ref={videoRef} autoPlay playsInline />
                </Col>
              </Row>
            </Col>
            {prioritizedDeviceList.length > 1 && (
              <Col className="d-flex justify-content-center">
                <p className="camera-select-txt p-0 m-0 mt-2">
                  {t('camera_selector.rear_camera_selection')}
                </p>
              </Col>
            )}
            <Col className={classList}>
              {!examInProgress && (
                <Button variant="secondary" size="sm" onClick={goBack}>
                  {t('nav_button.back')}
                </Button>
              )}
              <ButtonGroup
                className="camera-selector"
                onClick={(e) => {
                  setSelectedCamera(e.target.getAttribute('data-cameraname'));
                }}
              >
                {prioritizedDeviceList.length > 1 &&
                  prioritizedDeviceList.map((d) => {
                    return (
                      <Button
                        variant={
                          selectedDevice == d.device.deviceId
                            ? 'primary'
                            : 'secondary'
                        }
                        size="sm"
                        data-cameraname={d.device.deviceId}
                        key={d.device.deviceId}
                      >
                        {d.label}
                      </Button>
                    );
                  })}
              </ButtonGroup>
              {!examInProgress && (
                <Button variant="secondary" size="sm" onClick={goNext}>
                  {t('nav_button.next')}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CameraSelector;
